import axios from "axios";

class Services {
    constructor() {
        console.log("Initializing axios")
    }

    async sendEmail(fullname, email, phonenumber, serviceItems, projectDetail) {
        const response = await axios.post("https://moonshotanimationspaymentapi.herokuapp.com/email", {
            name: fullname,
            email: email,
            phone: phonenumber,
            services: serviceItems,
            projectDetail: projectDetail
        });
        return response.data;
    }

    async sendQuickResponseEmail(fullname, email, phonenumber, serviceItems) {
        const response = await axios.post("https://moonshotanimationspaymentapi.herokuapp.com/quickresponseemail", {
            name: fullname,
            email: email,
            phone: phonenumber,
            services: serviceItems
        });
        return response.data;
    }
    async paymentConfirmationEmail(itemList, price, fname, email, number, category, name) {
        const response = await axios.post("https://moonshotanimationspaymentapi.herokuapp.com/paymentsuccess", {
            itemList: itemList,
            price: price,
            fname: fname,
            email: email,
            number: number,
            category: category,
            name: name
        });
        return response.data;
    }

}

export default new Services();
