import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import checkGifIcon from "../../images/check-loading.png";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const ThankYou = () => {

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Thank You - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden thankyouPage">
                <div className="text-center">
                    <img src={checkGifIcon} className="checkGifIcon" alt="Success" />
                    <h2
                        className="mainHeading varient4"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Thank you!
                    </h2>
                </div>
                <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                >
                    Your query is received and we will contact you with in 24 hours.
                </p>
                <div className="mb-53">
                    <a
                        className="btn btnGradientOne"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        href="/"
                    >Back to Home</a>
                </div>
            </section>

            <Footer />
        </div>
    )
}
export default ThankYou;